import React from 'react';
// import resumePDF from '../../images/Clinton-Sebastian-resume.pdf';
import LanguageTools from '../LanguageTools';

function Resume() {
  return (
    <section className="resume-section">
      <h2 className="title-2">Resume.</h2>
      <div className="history-container">
        <div className="history">
          <h4 className="title-4">Work History</h4>
          <div className="work-history">
            <p>01 <span className="sp-ml1"></span><span className="sp-purple">{`const`}</span> <span className="sp-orange">{`experienceDetails`}</span> <span className="sp-teal">{` = `}</span> <span className="sp-purple">{`[`}</span></p>
            <p>02 <span className="sp-purple sp-ml2">{`{`}</span></p>
            <p>03 <span className="sp-ml3"> <span className="sp-red">{`title`}</span> : <span className="sp-green">{`"Full Stack Developer"`}</span>,</span></p>
            <p>04 <span className="sp-ml3"> <span className="sp-red">{`company`}</span> : <span className="sp-green">{`"Innomactic"`}</span>,</span></p>
            <p>05 <span className="sp-ml3"> <span className="sp-red">{`yearsExperience`}</span> : <span className="sp-orange">1</span>,</span></p>
            <p>06 <span className="sp-ml3"> <span className="sp-red">{`location`}</span> : <span className="sp-green">{`"Toronto (Remote)"`}</span>,</span></p>
            <p>07 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
            <p>08 <span className="sp-purple sp-ml2">{`{`}</span></p>
            <p>09 <span className="sp-ml3"> <span className="sp-red">{`title`}</span> : <span className="sp-green">{`"Web Developer II"`}</span>,</span></p>
            <p>10 <span className="sp-ml3"> <span className="sp-red">{`company`}</span> : <span className="sp-green">{`"Q4 Inc"`}</span>,</span></p>
            <p>11 <span className="sp-ml3"> <span className="sp-red">{`yearsExperience`}</span> : <span className="sp-orange">2</span>,</span></p>
            <p>12 <span className="sp-ml3"> <span className="sp-red">{`location`}</span> : <span className="sp-green">{`"Toronto (Remote)"`}</span>,</span></p>
            <p>13 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
            <p>14 <span className="sp-purple sp-ml2">{`{`}</span></p>
            <p>15 <span className="sp-ml3"> <span className="sp-red">{`title`}</span> : <span className="sp-green">{`"Web Developer"`}</span>,</span></p>
            <p>16 <span className="sp-ml3"> <span className="sp-red">{`company`}</span> : <span className="sp-green">{`"Niukraids"`}</span>,</span></p>
            <p>17 <span className="sp-ml3"> <span className="sp-red">{`yearsExperience`}</span> : <span className="sp-orange">3</span>,</span></p>
            <p>18 <span className="sp-ml3"> <span className="sp-red">{`location`}</span> : <span className="sp-green">{`"Toronto (Remote)"`}</span>,</span></p>
            <p>19 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
            <p>20 <span className="sp-purple sp-ml1">{`]`}</span></p>
          </div>
        </div>
        <div className="history">
        <h4 className="title-4">Education History</h4>
          <div className="work-history">
          <p>01 <span className="sp-ml1"></span><span className="sp-purple">{`const`}</span> <span className="sp-orange">{`educationDetails`}</span> <span className="sp-teal">{` = `}</span> <span className="sp-purple">{`[`}</span></p>
          <p>02 <span className="sp-purple sp-ml2">{`{`}</span></p>
          <p>03 <span className="sp-ml3"> <span className="sp-red">{`program`}</span> : <span className="sp-green">{`"Vue, React, Python, AI & TypeScript"`}</span>,</span></p>
          <p>04 <span className="sp-ml3"> <span className="sp-red">{`field`}</span> : <span className="sp-green">{`"Latest Stacks & Languages"`}</span>,</span></p>
          <p>05 <span className="sp-ml3"> <span className="sp-red">{`yearCompleted`}</span> : <span className="sp-orange">2023</span>,</span></p>
          <p>06 <span className="sp-ml3"> <span className="sp-red">{`school`}</span> : <span className="sp-green">{`"LinkedIn Learning"`}</span>,</span></p>
          <p>07 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
          <p>08 <span className="sp-purple sp-ml2">{`{`}</span></p>
          <p>09 <span className="sp-ml3"> <span className="sp-red">{`program`}</span> : <span className="sp-green">{`"Full Stack Development"`}</span>,</span></p>
          <p>10 <span className="sp-ml3"> <span className="sp-red">{`field`}</span> : <span className="sp-green">{`"Front end & Back End"`}</span>,</span></p>
          <p>11 <span className="sp-ml3"> <span className="sp-red">{`yearCompleted`}</span> : <span className="sp-orange">2021</span>,</span></p>
          <p>12 <span className="sp-ml3"> <span className="sp-red">{`school`}</span> : <span className="sp-green">{`"University of Toronto"`}</span>,</span></p>
          <p>13 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
          <p>14 <span className="sp-purple sp-ml2">{`{`}</span></p>
          <p>15 <span className="sp-ml3"> <span className="sp-red">{`program`}</span> : <span className="sp-green">{`"Web Development"`}</span>,</span></p>
          <p>16 <span className="sp-ml3"> <span className="sp-red">{`field`}</span> : <span className="sp-green">{`"Web Design & Development"`}</span>,</span></p>
          <p>17 <span className="sp-ml3"> <span className="sp-red">{`yearCompleted`}</span> : <span className="sp-orange">2016</span>,</span></p>
          <p>18 <span className="sp-ml3"> <span className="sp-red">{`school`}</span> : <span className="sp-green">{`"Codecademy"`}</span>,</span></p>
          <p>19 <span className="sp-ml2"> <span className="sp-purple">{`}`}</span>,</span></p>
          <p>20 <span className="sp-purple sp-ml1">{`]`}</span></p>
          </div>
        </div>
      </div>

      <LanguageTools />
      <div className="resume-download">
        <h3 className="title-3">Discover More in My Resume</h3>
        <p>Ready to dive deeper into my professional journey? <br/>Click below to explore my full range of skills, experiences, and accomplishments. Let's embark on this journey together!</p>
        <a className="downloadButton" target="_blank" rel="noreferrer" href="https://resume.pravton.com/">
        <i className="fas fa-file-alt"></i> <span className='sp-ml1'>View/Download Resume</span>
        </a>
      </div>
    </section>
  )
}

export default Resume;