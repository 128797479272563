import ContactIcons from "../ContactIcons";
import Badge from "../Badge";

function About({ setCurrentLink }) {
  return (
    <section id="about" className="about-section">
      <Badge />
      <h2 className="title-2">About Me.</h2>
      <p className="description">
        <span>
          As a passionate and dedicated Full-Stack Developer, I bring a unique blend of creative coding prowess and practical experience. My academic journey at the University of Toronto's Full-Stack Web Development Program laid a strong foundation in HTML, CSS, JavaScript, and TypeScript, enabling me to craft engaging, dynamic user experiences.
        </span>
      </p>
      <p className="description">
        <span>
          My expertise extends to mastering responsive web design and a suite of front-end and back-end technologies, with a focus on JavaScript frameworks like React.js, Next.js, and Vue.js. Equally proficient in Node.js, I excel at constructing efficient, scalable server-side applications. This skill set has been further enhanced by my experience developing web and mobile applications for diverse clients such as Road Warrior, DCL, Granite Fuel, and Cake Delivery Toronto.
        </span>
      </p>
      <p className="description">
        <span>
          My toolbelt includes Git, Github and Adobe's creative suite including Photoshop, Illustrator, and XD, Figma complementing my technical skills with innovative creativity. Known for my detail-oriented approach, I consistently meet deadlines and thrive in collaborative environments, always eager to embrace new learning opportunities.
        </span>
      </p>
      <p className="description">
        <span>
          Each project is an opportunity to reaffirm my commitment to intuitive UI/UX design and agile development practices. I am driven by the ambition to enhance user journeys through innovative and user-centric designs. With every line of code, I strive to exceed user expectations, ensuring every digital experience is not just functional, but also engaging and memorable.
        </span>
      </p>
      <p className="description">
        <span className="cust-links">
          <strong>
            Any ideas on mind?{" "}
            <span
              className="sp-connect"
              onClick={() => setCurrentLink("Contact")}
            >
              <i className="fas fa-handshake"></i> Let's connect!
            </span>
          </strong>
        </span>
      </p>
      <ContactIcons />
    </section>
  );
}

export default About;
