import React, { useRef } from 'react';

const Badge = () => {
    const badgeRef = useRef(null);

    const handleMouseMove = (e) => {
        const badge = badgeRef.current;
        const rect = badge.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;

        const maxTilt = 40; // Max tilt angle
        const tiltX = (y / rect.height) * maxTilt;
        const tiltY = -(x / rect.width) * maxTilt;

        badge.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;
    };

    const handleMouseLeave = () => {
        const badge = badgeRef.current;
        badge.style.transform = 'rotateX(0) rotateY(0)';
    };

    return (
        <div className="badge-container">
            <div
              className="badge"
              ref={badgeRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            ></div>
        </div>
    );
};

export default Badge;