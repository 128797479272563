import booklyst from '../../images/projects/booklyst.jpg';
import coworkers from '../../images/projects/coworkers.jpg';
import weatherDashboard from '../../images/projects/weather-dashboard.jpg';
import covidDashboard from '../../images/projects/covidDashboard.jpg';
import budgetTracker from '../../images/projects/budget-tracker.jpg';
// import foodFestival from '../../images/projects/food-festival.jpg';
import getItDone from '../../images/projects/get-it-done.JPG';
import zooKeeper from '../../images/projects/zoo-keeper.jpg';
import taskinator from '../../images/projects/taskinator.JPG';
import cdt from '../../images/projects/cdt.jpg';
import dprint from '../../images/projects/dprint.jpg';
import bcakes from '../../images/projects/bcakes.jpg';
import gfuel from '../../images/projects/gfuel.jpg';
import rwarrior from '../../images/projects/rwarrior.jpg';
import lvApp from '../../images/projects/lv-app.jpg';
import aiChatApp from '../../images/projects/ai-chat-app.jpg';
import ceylonPages from '../../images/projects/ceylon-pages.jpg';
import reviveMSC from '../../images/projects/reviveMSC.jpg';
import innomactic from '../../images/projects/innomactic.jpg';

function Work() {

  const works = [
    {
      projectLink: 'https://roadwarrior-inc.com/lightningvolt-bluetooth-monitoring-app-roadwarrior/',
      title: 'LightningVolt App',
      description: 'Flutter, Dart, Flutter Blue Plus, iOS & Android',
      bgImage: lvApp,
    },
    {
      projectLink: 'https://chat.innomactic.com/',
      title: 'Innomactic AI Chat',
      description: 'React, Next.js, Varcel KV, Node, Express, Tailwind CSS, Varcel AI SDK',
      bgImage: aiChatApp,
    },
    {
      projectLink: 'https://booklyst-project.herokuapp.com/',
      title: 'Booklyst. A book search engine',
      description: 'HTML, CSS, Javascript, Node, Express, GraphQL, Mongoose, Redux & Bootstrap',
      bgImage: booklyst,
      github: 'https://github.com/group3uoft/final-project'
    },
    {
      projectLink: 'https://roadwarrior-inc.com/',
      title: 'Roadwarrior Inc',
      description: 'HTML, CSS, Javascript, PHP, Wordpres & WooCommerce, Elementor',
      bgImage: rwarrior,
    },
    {
      projectLink: 'https://granitefuel.com/',
      title: 'Granite Fuel',
      description: 'HTML, CSS, Javascript, PHP, Wordpress, Elementor',
      bgImage: gfuel,
    },
    {
      projectLink: 'https://blackcakes.ca/',
      title: 'Blackcakes',
      description: 'HTML, CSS, Javascript, PHP, Wordpress & WooCommerce, Elementor',
      bgImage: bcakes,
    },
    {
      projectLink: 'https://coworkers-group2.herokuapp.com/',
      title: 'Coworkers: Employee Management System',
      description: 'HTML, CSS, Javascript, Node, Express & MySQL',
      bgImage: coworkers,
      github: 'https://github.com/Group2Project2UofTCoding/coworkers'
    },
    {
      projectLink: 'https://dreamprint.lk/',
      title: 'DreamPrint',
      description: 'HTML, CSS, Javascript, PHP, Wordpress & WooCommerce, Elementor',
      bgImage: dprint,
    },
    {
      projectLink: 'https://cakedeliverytoronto.ca/',
      title: 'Cake Delivery Toronto',
      description: 'HTML, CSS, Javascript, PHP, Wordpress & WooCommerce, Elementor',
      bgImage: cdt,
    },
    {
      projectLink: 'https://pravton.github.io/weather-dashboard/',
      title: 'Weather Dashboard',
      description: 'HTML, CSS, & Javascript',
      bgImage: weatherDashboard,
      github: 'https://github.com/pravton/weather-dashboard'
    },
    {
      projectLink: 'https://coworkers-group2.herokuapp.com/',
      title: "What's Happening Dashboard",
      description: 'HTML, CSS & Javascript',
      bgImage: covidDashboard,
      github: 'https://github.com/Teamtoo222/Project-1-UofT'
    },
    {
      projectLink: 'https://ceylonpages.lk',
      title: 'Ceylon Pages',
      description: 'HTML, CSS, Javascript, PHP, Wordpress & WooCommerce, Elementor',
      bgImage: ceylonPages,
    },
    {
      projectLink: 'https://budget-tracker-simple.herokuapp.com/',
      title: 'Budget Tracker PWA',
      description: 'Javascript, Node, Express, MongoDB & Mongoose',
      bgImage: budgetTracker,
      github: 'https://github.com/pravton/simple-budget-tracker-pwa'
    },
    {
      projectLink: 'https://innomactic.com',
      title: 'Innomactic',
      description: 'HTML, CSS, Javascript, React, PHP, Wordpress',
      bgImage: innomactic,
    },
    // {
    //   projectLink: 'https://pravton.github.io/food-festival/',
    //   title: 'Food Festival',
    //   description: 'HTML, CSS, Javascript, IndexedDB, Webpack & PWA',
    //   bgImage: foodFestival,
    //   github: 'https://github.com/pravton/food-festival'
    // },
    {
      projectLink: 'https://pravton.github.io/get-it-done/',
      title: "Get it Done!",
      description: 'HTML, CSS & Javascript',
      bgImage: getItDone,
      github: 'https://github.com/pravton/get-it-done'
    },
    {
      projectLink: 'https://secret-anchorage-53620.herokuapp.com/',
      title: 'Zoo-Keeper',
      description: 'HTML, CSS, Javascript, Node, Express',
      bgImage: zooKeeper,
      github: 'https://github.com/pravton/zookeepr'
    },
    {
      projectLink: 'https://pravton.github.io/taskinator/',
      title: 'Taskinator',
      description: 'HTML, CSS, & Javascript',
      bgImage: taskinator,
      github: 'https://github.com/pravton/taskinator'
    },
    {
      projectLink: 'https://revivemsc.ca/',
      title: 'Revive MSC',
      description: 'HTML, CSS, Javascript, PHP, Wordpress & WooCommerce, Elementor',
      bgImage: reviveMSC,
    },
    // {
    //   projectLink: 'http://just-tech-blog.herokuapp.com/',
    //   title: 'Tech Blog',
    //   description: 'HTML, CSS, Javascript, Node, Express, MySQL & Sequelize',
    //   bgImage: require(`../../images/projects/tech-blog.jpg`).default,
    //   github: 'https://github.com/pravton/tech-blog'
    // },
    // {
    //   projectLink: 'https://pravton.github.io/team-profile-generator/',
    //   title: 'Team Profile Generator CLI',
    //   description: 'HTML, CSS, Javascript & Node',
    //   bgImage: require(`../../images/projects/team-profile.jpg`).default,
    //   github: 'https://github.com/pravton/team-profile-generator'
    // },
  ]

  function linkClick (link) {
    window.open(link, '_blank');
  }

  return (
    <section id="works">
    <h1 className="title-2">Recent Projects.</h1>  
    <div className="works">
      {works.map((work, i) => (
        <div className="work" key={i} style={{backgroundImage: `url(${work.bgImage})`}}>
          <a href={work.projectLink} target="_blank" rel="noreferrer" className="work-container">
              <div className="work-info" >
                  <h3>{work.title}</h3>
                  <p>{work.description}</p>
                  <div className="work-info-icons">
                    {
                      work.github ? 
                      <span onClick={() => linkClick(work.github)} className="wii-link"><i className="fab fa-github"></i></span>
                      : ''
                    }
                    <span onClick={() => linkClick(work.projectLink)} className="wii-link"><i className="fas fa-globe"></i></span>
                </div>
              </div>
          </a>
        </div>
      ))}
    </div>  
    </section>
  )
}

export default Work;